.tablaPedidos {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.datePicker {
    width: 300px;
    margin-left: 280px;
    margin-top: 70px;
}

.estadisticas {
    font-size: large;
    margin-left: 280px;
    margin-top: 70px;
}