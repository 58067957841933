.tablaPedidos {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.formElement {
    margin: 30px;
}

.formContainer {
    font-size: large;
    display: flex;
    justify-content: center;
}

.generarBtn {
    background-color: #ff4364!important;
}