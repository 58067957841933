.pedidosContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-left: 2rem;
    margin-right: 2rem;
}

.gridPedidos {
    width: 100%!important;
}

.pedidosLabel {
    text-align: center;
    border-width: 2px;
    border-color: black;
    border-style: solid;
}

.itemPedido {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 95%;
        border-radius: 10px;

    margin-right: 5%;
    height: 200px;
    background-color: #FFFFA5;

}

.red {
    color: red;
}

.itemConfirmado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 10px;

    font-size:	0.80rem;
    margin-right: 5%;
    width: 95%!important;
    height: 200px;
    background-color: #ff65a3;
}

.itemEnEntrega {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 10px;

    width: 100%;
    font-size:	0.80rem;
    /*height: 200px;*/
    background-color: 	#7afcff;
}

.itemCompletado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 10px;

    font-size:	0.80rem;
    width: 95%;
    height: 166px;
    margin-right: 5%;
    background-color: #fff740;
}

.itemCancelado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    border-radius: 10px;

    width: 100%;
    height: 200px;
    background-color: #c0311e;
    margin-right: 10px;
}
.itemAfuera {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 10px;

    font-size:	0.80rem;
    width: 100%;
    height: 200px;
    background-color: #c940ff;
}

.itemPersonalizado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size:	0.80rem;
    margin-right: 5%;
    width: 95%!important;
    height: 200px;
    background-color: #ff4364;
}


.moveBtn {
    width: 20px!important;
}

.modalBodie {
    /*max-height: 600px;*/
    overflow-y: auto;
}


.modalBody {
    /*height: 139px;*/
    overflow-y: auto;
    background: white;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem!important;
}

.modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.btnVerificar {
    margin-top: 20px!important;
}

.zonaClickeable {
    width: 162px;
    height: 60px;
    position: absolute;
    cursor: pointer;
}
.texto-encabezado {
    font-size: large;
}

.width-80 {
    width: 80%;
}

.containerProgramados{
    margin-top: 5%;
    display: flex;
}

.cardProgramados {
    margin-top: 5%;
    width: 100%;
    margin-left: 5%;
}

.containerNumeros{
    margin-left: 15%;
    margin-top: 5%;
}
