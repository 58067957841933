.container{
    margin-top: 5rem;
    width: 80%;
}

.header{
    color: #ff4364!important;
    font-family: sans-serif!important;
    font-size: 1rem!important;
}

.btnVerificar {
    color:#ff4364!important;
    margin-top: -2px!important;
}

.btnCambiar {
    color:#19a9e2!important;
}
.btnCupon {
    margin-top: -2px!important;
    color:#ff4364!important;
}
.MuiButton-outlined {
    border: 1px solid #ff4364!important;
}

.filter{
    justify-content: flex-end;
    margin-bottom: 1rem!important;
    margin-left: 0.5rem!important;
}

.modalBody {
    /*height: 139px;*/
    overflow-y: auto;
    background: white;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10%;
}
.modalBodyCupon {
    height: 177px;
    overflow-y: auto;
    background: white;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10%;
}

.modalBodyCopiarProductos{
    background: white;
    padding: 5%;
}

.modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.btnVerificar {
    margin-top: 20px!important;
}
