.tablaPedidos {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.datePicker {
    display: block;
}

.estadisticas {
    font-size: large;
    margin-left: 280px;
    margin-top: 70px;
}


.btnReporte {
    margin-left: 83px!important;
}

.tablaPuntajes {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}