.cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.cardLogin { 
    width: 50%;
}

.textInputLogin {
    width: 100%;
}

.loginBtn {
    background-color: #ff4364!important;
    width: 50%;
    height: 45px;
}

.loginBtnContainer {
    justify-content: center
}

