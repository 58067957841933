.rowContainer {
    margin-top: 5%;
}

.containerBusinessForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttonsForm {
    display: flex;
    flex-direction: row;
}

.form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textInput {
    width: 100%!important
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkboxCadete {
    display: flex;
    align-items: center;
    justify-content: flex-start!important;
}
.container-tournament-form .form-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin-bottom: 25px;
}

#component-error-text {
    color: #f44336;
}

.multiple-checbox-selection, .select-multiple-checkbox{
    width: 100%;
    max-width: 100%;
}

.nameInput {
    width: 100%
}

.descriptionInput {
    width: 100%;
    height: 180px;
}

.descriptionInput .MuiInputBase-root{
    height:180px;
}

.tipoInput {
    margin-top: 0px!important;
}

.row {
    margin-bottom: 15px;
}
.datepickerStyle {
    margin-top: 7px;
}

.MuiFormControl-root .MuiTextField-root {
    width: 100%;
}

.nameInputError {
    width: 100%
}

.nameInputError .MuiInputLabel-filled {
    color: #f44336;
}


.nameInputError .MuiFilledInput-underline:after {
    transform: scaleX(1);
    border-bottom-color: #f44336;
}

.nameInputError .MuiFormLabel-root.Mui-focused {
    color: #f44336;
}

.nameInputError .Mui-focused:after {
    border-bottom: 2px solid #f44336;
}

.mensajeErrorNombre {
    color: #f44336!important;
    font-size: 0.8rem!important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
    font-weight: 400!important;
    margin-top:5px;
    margin-left: 12px;
    line-height: 1!important;
    letter-spacing: 0.00938em!important;
}

.MuiFormControl-marginNormal {
    width: 100%
}

.container-tournament-form {
    margin-top: 5%;
}