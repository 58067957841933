.pedidosContainer {
    display: flex;
    align-items: center;
    margin-top: 5%;
}

.gridPanelPedidos {
    float: left;
}

.pedidosLabelPanel {
    text-align: center;
    width: 100px;
    height: 200px;
    margin-right: 30px;
}

.containerPedidos {
    width: 100%;
    display: flex;
    margin-left: 4%;
    overflow-y: scroll!important;
    height: 600px;
}

.itemPedido {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 95%;
    margin-right: 5%;
    height: 200px;
    background-color: #FFFFA5;

}

.red {
    color: red;
}

.itemConfirmado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    margin-right: 5%;
    width: 100%;
    height: 200px;
    background-color: #ff65a3;
}

.itemEnEntrega {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size:	0.80rem;
    /*height: 200px;*/
    background-color: 	#7afcff;
}

.itemCompletado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 95%;
    height: 166px;
    margin-right: 5%;
    background-color: #fff740;
}

.itemCancelado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 100%;
    height: 200px;
    background-color: #c0311e;
}
.itemAfuera {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 100%;
    height: 200px;
    background-color: #c940ff;
}
.moveBtn {
    width: 20px!important;
}

.modalBodie {
    /*max-height: 600px;*/
    overflow-y: auto;
}


.modalBody {
    /*height: 139px;*/
    overflow-y: auto;
    background: white;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10%;
}

.modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.btnVerificar {
    margin-top: 20px!important;
}

.zonaClickeable {
    width: 162px;
    height: 60px;
    position: absolute;
    cursor: pointer;
}
.texto-encabezado {
    font-size: large;
}

.width-80 {
    width: 80%;
}

.containerPanel {
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    max-height: 100px;
    margin-left: 20px;
    margin-right: 20px;
    /*overflow: hidden;*/
}
.containerCadetes {
    width: 100%;
    overflow: auto;
    display: flex;
    margin-left: 4%;
    max-height: 600px;
    min-height: 20px;
}

.labelNegocio {
    font-size: 15px;
    max-width: 150px;

}


.panelCadete {
    display: flex;
}
.cadeteLabel {
    text-align: center;
    margin-left: 5%;
    align-self: center;
}

.Recibido {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 170px;
    height: 200px;
    margin-right: 15px;
    background-color: #FFFFA5;

}

.red {
    color: red;
}

.Búsqueda {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 170px;
    height: 200px;
    margin-right: 15px;
    background-color: #ff65a3;
}

.EnCamino {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 170px;
    font-size:	0.80rem;
    margin-right: 15px;
    /*height: 200px;*/
    background-color: 	#7afcff;
}

.Finalizado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 170px;
    height: 200px;
    margin-right: 15px;
    background-color: #fff740;
}

.Cancelado {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    margin-right: 15px;
    width: 170px;
    height: 200px;
    background-color: #c0311e;
}
.Afuera {
    box-shadow: 7px 11px 12px -9px rgba(0,0,0,1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:	0.80rem;
    width: 170px;
    height: 200px;
    margin-right: 15px;
    background-color: #c940ff;
}
.ciudadLabel {
    font-size: 28px;
    /*height: 100px;*/
}
